// Migrated
<template lang="pug">
div(ref="container")
  slot(v-bind="{ inView }")
</template>

<script>
export default defineNuxtComponent({
  name: 'InView',

  props: {
    scrollTarget: {
      type: [htmlTypes.HTMLElement, ...(process.browser ? [Window] : [])],
      default: process.browser ? () => window : null
    }
  },

  emits : ['trigger'],

  data () {
    return {
      inView: false
    }
  },

  watch: {
    scrollTarget (newTarget, prevTarget) {
      try {
        prevTarget?.removeEventListener('scroll', this.onScrollListener)
        newTarget?.addEventListener('scroll', this.onScrollListener)
      } catch {
        // Do nothing
      }
    }
  },

  beforeMount () {
    try {
      this.scrollTarget?.addEventListener('scroll', this.onScrollListener)
    } catch {
      // Do nothing
    }
  },

  mounted () {
    this.onScrollListener()
  },

  updated () {
    this.onScrollListener()
  },

  beforeUnmount () {
    try {
      this.scrollTarget?.removeEventListener('scroll', this.onScrollListener)
    } catch {
      // Do nothing
    }
  },

  methods: {
    getScrollCoordinates () {
      const scrollTargetTop = this.scrollTarget instanceof Window ? 0 : this.scrollTarget?.getBoundingClientRect()?.top || 0

      return {
        top: (this.$refs.container.getBoundingClientRect()?.top - scrollTargetTop) || 0,
        innerHeight: this.scrollTarget?.offsetHeight ?? this.scrollTarget?.innerHeight,
        offset: 150,
      }
    },

    onScrollListener () {
      if (!this.$refs.container || !process?.browser || !this.scrollTarget) {
        return
      }

      const { top, innerHeight, offset } = this.getScrollCoordinates()

      if (top < innerHeight + offset) {
        this.$emit('trigger')
        this.inView = true
      }
    },
  }
})
</script>
