const htmlElementTypes = ['HTMLElement', 'HTMLDivElement']
const htmlElementTypesObject: {
  [key: string]: object
} = htmlElementTypes.reduce(
  (current: object, type: string) => ({
    ...current,
    [type]: process.browser ? window[type as keyof typeof window] : {}
  }), {}
)

export default htmlElementTypesObject
